<template>
  <div class="container mt-3">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black">
            <i class="fas fa-home" />
          </router-link>
        </li>
        <li class="breadcrumb-item active">Peti Masuk</li>
      </ol>
    </nav>
    <div class="contents mt-4 mb-4">
      <div class="p-3 text-center bg-white rounded shadow-sm" v-if="isLoading">
        <span class="spinner-border spinner-border-sm" />
      </div>
      <div class="row mx-0 g-2 inbox-page-content" v-else>
        <div class="col-md-4 inbox-card">
          <div
            class="
              inbox-list-container
              h-100
              overflow-hidden
              border
              rounded
              bg-white
            "
          >
            <div class="p-3 border-bottom bg-light">
              <p class="mb-0">
                <i class="fas fa-envelope me-2" />
                Peti Masuk
              </p>
            </div>
            <div class="inbox-list-items">
              <div class="list-group list-group-flush border-bottom">
                <button
                  class="list-group-item list-group-item-action"
                  :class="{ active: currentInbox.id == inbox.id }"
                  v-for="inbox in inboxItems"
                  :key="inbox.id"
                  @click="selectInbox(inbox)"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p class="mb-0">
                        <span
                          class="badge bg-danger me-1"
                          v-if="!readInboxItems.find((ri) => ri.id == inbox.id)"
                        >
                          Baru
                        </span>
                        {{ inbox.attributes.subject }}
                      </p>
                      <p class="mb-0 text-muted" style="font-size: 0.8em">
                        {{ convertDate(inbox.attributes.createdAt) }}
                      </p>
                    </div>
                    <i
                      class="fas fa-angle-right"
                      v-if="currentInbox.id == inbox.id"
                    />
                  </div>
                </button>
                <div
                  class="
                    list-group-item
                    bg-light
                    text-muted text-center
                    fst-italic
                    fs-6
                    small
                  "
                  v-if="inboxItems.length == 0"
                >
                  Peti masuk kosong
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 inbox-card">
          <div
            id="inbox-content-container"
            class="h-100 rounded overflow-auto border bg-white p-3"
          >
            <div
              class="text-center fst-italic text-muted"
              v-if="!currentInbox.id"
            >
              <small>Tiada item dipilih</small>
            </div>
            <div
              class="inbox-contents-html"
              v-html="Marked.parse(currentInbox.content)"
              v-if="currentInbox.content"
            ></div>
            <!-- <div
              class="inbox-contents-html"
              v-html="currentInbox.html_content"
              v-if="currentInbox.html_content"
            ></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import moment from "moment";
import { marked } from "marked";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      currentInbox: {
        id: null,
        content: null,
      },
      Marked: marked,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "inboxItems", "readInboxItems"]),
  },
  methods: {
    ...mapActions(["updateReadInboxItems"]),
    async selectInbox(inbox) {
      this.currentInbox = {
        id: inbox.id,
        content: inbox.attributes.content,
      };

      this.$nextTick(() => {
        const container = document.getElementById("inbox-content-container");

        container.scrollTop = 0;
      });

      this.updateReadInboxItems(inbox);
    },
    convertDate(datetime) {
      return moment(datetime).locale("ms-My").format("dddd, D MMM YYYY");
    },
  },
  async mounted() {
    const renderer = {
      link(href, title, text) {
        return `
					<video
						class="video-js"
						controls
						preload="auto"
						poster="MY_VIDEO_POSTER.jpg"
						data-setup="{}"
					>
						<source src="https://${href}" type="video/mp4" />
					</video>
        `;
      },
    };

    this.Marked.use({ renderer });
  },
};
</script>

<style lang="scss" scoped>
.list-group-item-action.active {
  background-color: #e3e5e8;
  border-color: #e3e5e8;
  color: #000000;
}

.inbox-card {
  height: calc(100% - 0.5rem);
  overflow: hidden;

  @media (max-width: 767.98px) {
    height: calc(50% - 0.5rem);
  }
}

.inbox-page-content {
  height: calc(100vh - 84px - 94px - 64px - 1rem);
  overflow: hidden;
}

.inbox-list-items {
  height: calc(100% - 54px);
  overflow: auto;
}

.inbox-contents-html {
  text-align: justify;
  text-justify: inter-word;

  ::v-deep img {
    width: 100%;
    padding: 10px 0px;
  }

  ::v-deep iframe {
    width: 100%;
    padding: 10px 0px;
  }
}
</style>